<template>
  <div>
    <CRow>
      <CCol md="12" v-if="show">
        <CCard class="topCard">
          <CCardHeader class="topCardHeader">
            <div class="d-flex justify-content-between align-items-center">
              <div class="h4 m-0">Grup Yetkilendirilmesi</div>
              <div class="d-flex justify-content-end" style="min-width: 550px">
                <input
                  type="text"
                  v-model="searchFilterInput"
                  autocomplete="off"
                  class="form-control mr-3"
                  style="max-width: 250px"
                  placeholder="Arama..."
                  v-on:keyup="onFilterTextBoxChanged()" />
                <RoleProvider slug="ADMIN_ROLE_CREATE">
                  <div slot-scope="{ canItPass }">
                    <CButton
                      v-if="canItPass"
                      color="light"
                      variant="outline"
                      size="sm"
                      @click="createModalOpen"
                      class="py-2 px-3 font-weight-bold">
                      <font-awesome-icon
                        :icon="['fas', 'plus']"
                        class="mr-2" />Yeni Ekle
                    </CButton>
                  </div>
                </RoleProvider>
              </div>
            </div>
          </CCardHeader>

          <CCardBody class="p-0">
            <ag-grid-vue
              style="width: 100%; height: calc(100vh - 145px)"
              class="ag-theme-balham fp-table"
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              :rowData="list"
              :sideBar="sideBar"
              rowSelection="multiple"
              :enableRangeSelection="true"
              :statusBar="statusBar"
              @rowDoubleClicked="onRowDoubleClicked"
              @grid-ready="onGridReady"></ag-grid-vue>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <v-dialog style="z-index: 1031 !important" v-model="modal" fullscreen>
      <v-card>
        <v-toolbar dense dark color="primary" fixed>
          <v-toolbar-title>Grup Yetkilendirilmesi</v-toolbar-title>
          <v-spacer></v-spacer>

          <v-spacer></v-spacer>

          <RoleProvider slug="ADMIN_ROLE_DELETE">
            <div slot-scope="{ canItPass }">
              <v-btn
                v-if="process == 'put' && canItPass"
                @click="deleted"
                dark
                text>
                SİL
              </v-btn>
            </div>
          </RoleProvider>

          <RoleProvider slug="ADMIN_ROLE_UPDATE">
            <div slot-scope="{ canItPass }">
              <v-btn
                dark
                text
                @click="process == 'put' ? updated() : created()"
                v-if="canItPass">
                {{ process == "put" ? "DÜZENLE" : "EKLE" }}
              </v-btn>
            </div>
          </RoleProvider>
          <v-btn icon dark @click="modal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-col>
          <input
            type="text"
            v-model="role.name"
            autocomplete="off"
            class="form-control mr-3 mb-3"
            placeholder="Grup İsmi" />
          <v-treeview
            style="border: 1px solid #e5e7ea"
            rounded
            open-all
            selectable
            :items="rolesJSON"
            v-model="role.items"
            dense>
          </v-treeview>
        </v-col>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// ? Node modules.
import Vue from "vue";
import axios from "axios";
import VueSweetalert2 from "vue-sweetalert2";
const _ = require("lodash");

// ? Components
import { AgGridVue } from "ag-grid-vue";
import vSelect from "vue-select";
import { DatePicker } from "v-calendar";
import { RoleProvider } from "../../provider";

// ? Css.
import "vue-select/dist/vue-select.css";

// ? System usings.
axios.defaults.withCredentials = true;
Vue.use(VueSweetalert2);

// ? Environmets.
const { VUE_APP_API_URL } = process.env;

export default {
  name: "Role",
  components: {
    AgGridVue,
    vSelect,
    "v-date-picker": DatePicker,
    RoleProvider,
  },
  data() {
    return {
      selectionType: "leaf",
      // AG GRID
      searchFilterInput: "",
      show: true,
      modal: false,
      disabled: false,
      rates: [],
      list: [],
      title: "Grup yetkilendirme işlemleri",
      process: "post",
      id: "",
      rolesJSON: [],
      activeRoles: [],

      defaultGroupRoles: {
        name: "Yetkilendirme Seçiniz",
        items: [],
      },
      selectedGroupRoles: [
        {
          name: "Yetkilendirme Seçiniz",
          items: [],
        },
      ],
      role: {
        name: "",
        description: "",
        items: [],
      },
      form: {
        vendorSlug: "",
        status: "",
        productType: "",
        rates: [],
        startDate: "",
        endDate: "",
      },
      items: {
        vendors: [],
        productTypes: [],
      },
      columnDefs: [
        {
          field: "name",
          headerName: "İsim",
          width: 500,
          suppressSizeToFit: true,
        },
      ],
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true,
      },
      sideBar: {
        // Grid yan çubuk tanımlaması
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Sütunlar",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          ,
          {
            id: "filters",
            labelDefault: "Filtreler",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
        defaultToolPanel: "",
      },
      statusBar: {
        // Durum çubuğunda kullanılacak durum çubuğu bileşenlerini belirtir.
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" },
        ],
      },
    };
  },
  watch: {
    modal: function (value) {
      if (value) {
      } else {
        this.modalCleaner();
      }
    },
    role() {
      /**
     *   const fill = this.rolesJSON.map((rolesJ) => rolesJ.items);
      let allFill = [];
      const contactRoles = fill.reduce((pre, current) => {
        return pre.concat(current);
      }, allFill);
      this.activeRoles = contactRoles.filter((cRoles) =>
        this.role.items.includes(cRoles.slug)
      );
     */
    },
  },
  asyncComputed: {
    async getList() {
      try {
        let { data } = await axios.get(`${VUE_APP_API_URL}admin/role`);
        //Reverse data for new role
        this.list = _.reverse(data);
        // this.activeRoles=data.map(d=>)
      } catch (error) {}
    },
    async getRolesJSON() {
      try {
        let { data } = await axios.get(`${VUE_APP_API_URL}public/roles2.json`);

        this.rolesJSON = data.map((item, index) => ({
          id: Math.floor(Math.random() * new Date().getTime() + index),
          name: item.name,
          children: item.items.map((child, i) => ({
            id: child.slug,
            name: child.name,
            value: true,
          })),
        }));
      } catch (error) {}
    },
  },

  methods: {
    async checkBoxEvent(event) {
      const checked = event.target.checked;
      const value = event.target.value;
      console.log("value : ", value, " checked : ", checked);

      checked
        ? this.role.items.push(value)
        : (this.role.items = this.role.items.filter((e) => e !== value));
    },
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(this.searchFilterInput);
    },
    createModalOpen() {
      this.process = "post";
      this.title = "Grup Yetkilendirme Ekle";
      this.modal = true;
    },

    async created() {
      let body = this.createBody();
      console.log("created : ");
      console.log(body);
      body.items = this.role.items;
      try {
        const { data } = await axios.post(`${VUE_APP_API_URL}admin/role`, body);
        this.list = [data, ...this.list];
        Vue.swal({
          icon: "success",
          title: "Başarılı",
          confirmButtonText: "Tamam",
        });
        this.modal = false;
      } catch (error) {
        this.modal = false;
        console.log(error.response);
        console.log(error);
        Vue.swal({
          icon: "error",
          title: "Hata",
          html: "Grup Yetkilendirme Eklenemedi!",
          confirmButtonText: "Tamam",
        });
      }
    },

    async updated() {
      const update = { ...this.createBody(), items: this.role.items };
      const find = { _id: this.id };
      const body = { update, find };

      try {
        const { data } = await axios.put(`${VUE_APP_API_URL}admin/role`, body);
        if (data) {
          Vue.swal({
            icon: "success",
            title: "Başarılı",
            confirmButtonText: "Tamam",
          });
        }
        this.modal = false;
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } catch (error) {
        Vue.swal({
          icon: "error",
          title: "Hata",
          html: "Grup Yetkilendirme Düzenlenemedi!",
          confirmButtonText: "Tamam",
        });
      }
    },

    async deleted() {
      this.modal = false;
      Vue.swal({
        icon: "question",
        title: "Dikkat",
        text: "Silmek istediğinize emin misiniz?",
        showCancelButton: true,
        confirmButtonText: "Evet",
        cancelButtonText: "Hayır",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const ids = [{ _id: this.id }];
            await axios.delete(`${VUE_APP_API_URL}admin/role`, {
              data: { ids },
            });

            this.list = this.list.filter((l) => l.id !== this.id);
            this.modal = false;

            Vue.swal({
              icon: "success",
              title: "Başarılı",
              confirmButtonText: "Tamam",
            });
          } catch (error) {
            Vue.swal({
              icon: "error",
              title: "Hata",
              html: "Grup Yetkilendirme Eklenemedi!",
              confirmButtonText: "Tamam",
            });
          }
        }
      });
    },
    createBody() {
      console.log("createBody : ");
      console.log(this.role);
      const { name, description } = this.role;
      const body = {
        name: name.trim(),
        slug: name.turkishToUpperAndJoin().trim(),
      };
      if (description.length) body["description"] = description;
      return body;
    },
    onGridReady(params) {
      // ? Grid tablo hazır olduğunda çalışcak kodlar.
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      params.api.sizeColumnsToFit();
    },

    modalCleaner() {
      this.form.vendorSlug = "";
      this.form.startDate = "";
      this.form.endDate = "";
      this.form.status = "";
      this.form.rates = [];
      this.role = {
        name: "",
        description: "",
        items: [],
      };
      this.activeRoles = [];
    },

    onRowDoubleClicked(params) {
      this.process = "put";
      this.title = "Grup Yetkilendirme Düzenle";
      this.id = params.data.id;
      this.role = {
        name: params.data.name,
        description: params.data.description,
        items: params.data.items,
      };
      this.modal = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.v-treeview-node--rounded {
  background-color: red !important;
}
.v-treeview {
  display: flex !important;
  flex-wrap: wrap !important;
}

.checks {
  margin-top: 0.5rem;
  display: flex;
  flex-wrap: wrap;
}
.form-check {
  width: 50%;
}
.wrapper-checks {
  margin-top: 2rem !important;
  width: 95%;
  margin: auto;
}
.wrapperActiveRoles {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.wrapperActiveRoles li {
  width: 45%;
  margin: 3px 2px;
}
</style>
